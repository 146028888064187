import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtesalto.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3545209',
  schema: 'pbi_dte_salto',
  groupId: '315adfbc-dd43-41e8-9471-c815b4d9070f',
  analiseGeral: '255b46f1-10b0-4730-aab8-11a5a337895c',
  analiseUsuarios: '50315c31-18b3-43c7-98cf-de72702aba14',
  analiseComunicados: '435f6f86-80d6-48e4-95d2-ac3f8bdb60f3',
  analiseProcurador: '32575cc0-b5f0-4dba-b173-49bfb9b28526',
  analiseEventos: 'd68de142-ff9e-47e1-a9f8-812ef30bc1e5',
  analiseContribuintes: '9f409f4a-3504-4188-9fb7-08666f419736'
};
